const functions = require("../functions").default;

export function validationEvents(context) {
    // Select PF/PJ e Cliente construcao
    $(".js-select-pj", context).on("change", function () {
        const tipoPessoa = $(this).val();
        functions.atualizarMascara(tipoPessoa, context);
        functions.controlarVisibilidade(tipoPessoa, context);
    });

    // Checkbox PF(1)/PJ(3)
    $(".js-change-pj", context).on("change", function () {
        const tipoPessoa = $(this).is(":checked") ? "3" : "1";
        functions.atualizarMascara(tipoPessoa, context);
        functions.controlarVisibilidade(tipoPessoa, context);
    });

    $(".js-cpf-cnpj", context).on("change", function () {
        const valor = $(this).val();
        $("#cpf-cnpj-invalido", context).remove();
        if (
            $(".js-change-pj").is(":checked") ||
            $(".js-select-pj").val() == "3" ||
            $(".js-select-pj").val() == "4"
        ) {
            if (!isValidCNPJ(valor)) {
                const div = $(this).closest("div");
                div.append(
                    '<small class="a-color-taxa-perda" id="cpf-cnpj-invalido">CNPJ inválido!</small>'
                );
                if (!$(this).hasClass("is-invalid")) {
                    $(this).addClass("is-invalid");
                }
            } else {
                if ($(this).hasClass("is-invalid")) {
                    $(this).removeClass("is-invalid");
                }
            }
        } else {
            if (!isValidCPF(valor)) {
                const div = $(this).closest("div");
                div.append(
                    '<small class="a-color-taxa-perda" id="cpf-cnpj-invalido">CPF inválido!</small>'
                );
                if (!$(this).hasClass("is-invalid")) {
                    $(this).addClass("is-invalid");
                }
            } else {
                if ($(this).hasClass("is-invalid")) {
                    $(this).removeClass("is-invalid");
                }
            }
        }
    });

    $(".js-validar-cpf-cnpj", context).on("change", function () {
        $(this).unmask();
        if ($(this).hasClass("is-invalid")) {
            $(this).removeClass("is-invalid");
        }
        let valor = $(this).val();
        valor = valor.replace(/\D/g, "");
        $(this).val(valor.replace(/\D/g, ""));
        const length = valor.length;
        if (length > 11) {
            $(this).mask("00.000.000/0000-00", {
                reverse: true,
            });
            if (!isValidCNPJ(valor)) {
                const div = $(this).closest("div");
                if (!$(this).hasClass("is-invalid")) {
                    $(this).addClass("is-invalid");
                }
            } else {
                if ($(this).hasClass("is-invalid")) {
                    $(this).removeClass("is-invalid");
                }
                $(".js-is-pj").val(1);
            }
        } else if (length == 11) {
            $(this).mask("000.000.000-00", {
                reverse: true,
            });
            if (!isValidCPF(valor)) {
                const div = $(this).closest("div");
                if (!$(this).hasClass("is-invalid")) {
                    $(this).addClass("is-invalid");
                }
            } else {
                if ($(this).hasClass("is-invalid")) {
                    $(this).removeClass("is-invalid");
                }
                $(".js-is-pj").val(0);
            }
        }
    });
    $(".js-validar-sub-cpf-cnpj").on("submit", function (event) {
        event.preventDefault();
        if (!$(".js-validar-cpf-cnpj").hasClass("is-invalid")) {
            $(this).unbind("submit");
            $(this).submit();
        }
    });

    $(".js-cliente-estrangeiro", context).on("change", function () {
        if ($(this).is(":checked")) {
            $(".js-input-documento", context).removeClass("d-none");
            $(".js-input-cpf", context).addClass("d-none");
        } else {
            $(".js-input-documento", context).addClass("d-none");
            $(".js-input-cpf", context).removeClass("d-none");
        }
    });

    $(".js-enviar-senha", context).on("change", function () {
        if ($(this).is(":checked")) {
            $(".js-campos-senha", context).addClass("d-none");
        } else {
            $(".js-campos-senha", context).removeClass("d-none");
        }
    });

    // Contador de Caracteres
    $(".js-contar-caracteres", context).on("keyup", function (val) {
        $(this).closest(".form-group").find(".js-contador").remove();
        let div = $(this).closest(".form-group");
        let limite = $(div).data("contador");
        let total = this.value.length;

        if (total > limite) {
            this.value = this.value.substring(0, limite);
        } else {
            $(div).append(
                `<span class="label-contador js-contador">${total} de ${limite} caracteres</span>`
            );
        }
    });

    $(".js-contar-caracteres-variante", context).on("keyup", function (val) {
        $(this).closest(".form-group").find(".js-contador").remove();
        let div = $(this).closest(".form-group");
        let limite = $(this).data("contador");
        let total = this.value.length;

        if (total > limite) {
            this.value = this.value.substring(0, limite);
        } else {
            $(div).append(
                `<span class="label-contador js-contador"style="position: absolute; top: -16%;">${total} de ${limite} caracteres</span>`
            );
        }
    });

    $(".js-select-banner", context).on("change", function () {
        if (this.value == 1) {
            $(".banner").removeClass("d-none");
            $(".banner .banner--conteudo").removeClass("fluid");
        } else if (this.value == 2) {
            $(".banner").removeClass("d-none");
            $(".banner .banner--conteudo").addClass("fluid");
        } else {
            $(".banner").addClass("d-none");
        }
    });

    // Input inválido
    $("input.form-control.is-invalid", context).on("focus", function () {
        $(this).removeClass("is-invalid");
    });

    if ($(".js-form-config-loja", context).length) {
        functions.checkTrackingBlock();
    }

    $(".js-botao-adicionar-editar-tarefa", context).on("click", function () {
        let id = $(this).data("id");
        $(".js-div-mensagem-ativa").addClass("d-none");

        if ($(`#tipo${id}`).val() == "whatsapp") {
            $(`#template_id${id}`).attr("required", true);
            $(".js-div-mensagem-ativa").removeClass("d-none");
        }
    });

    $(".js-data-valida", context).on("change", function () {
        const padrao = new RegExp(
            "^([0-3]){1}([0-9]){1}/([0-1]){1}([0-9]){1}/([0-9]){4}$"
        );
        const valor = $(this).val();
        if (padrao.test(valor)) {
            if ($(this).hasClass("is-invalid")) {
                $(this).removeClass("is-invalid");
            }
        } else {
            if (!$(this).hasClass("is-invalid")) {
                $(this).addClass("is-invalid");
            }
        }
    });

    let tempKeyPressed = "";
    $(".js-telefone-validar-whatsapp", context).on("keyup", function (e) {
        tempKeyPressed += "" + e.key.replace(/\D/g, "");
        let inputVal = $(this).val().replace(/\D/g, "");
        if (inputVal.length < 11) {
            tempKeyPressed = inputVal;
        }

        if (e.ctrlKey && e.which === 86) {
            setTimeout(function () {
                functions.validarTelefone(e, context, tempKeyPressed, true);
            }, 400);
        }
    });

    $(".js-telefone-validar-whatsapp", context).on(
        "keyup",
        functions.delay(function (e) {
            functions.validarTelefone(e, context, tempKeyPressed);
        }, 400)
    );

    $(".js-form-obrigatorio", context).on("invalid", function (e) {
        if (!$(this).hasClass("is-invalid")) {
            $(this).addClass("is-invalid");
        }
        const tab = $(this).data("id");
        if (tab) {
            $(`#${tab}`).click();
        }
    });

    $(".js-form-obrigatorio", context).on("valid", function (e) {
        if ($(this).hasClass("is-invalid")) {
            $(this).removeClass("is-invalid");
        }
    });
}
